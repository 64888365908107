import {
  AppBar,
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountBalanceRounded from '@material-ui/icons/AccountBalanceRounded';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInRounded from '@material-ui/icons/AssignmentTurnedInRounded';
import CollectionIcon from '@material-ui/icons/AttachMoneyRounded';
// import HomeIcon from '@material-ui/icons/HomeRounded';
import DashboardIcon from '@material-ui/icons/DashboardRounded';
import DeveloperBoardRoundedIcon from '@material-ui/icons/DeveloperBoardRounded';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import SettingsIcon from '@material-ui/icons/SettingsRounded';
import ReportsIcon from '@material-ui/icons/FileCopyRounded';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import CloseIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import DAIcon from '@material-ui/icons/LocalShippingRounded';
import MenuIcon from '@material-ui/icons/MenuRounded';
import SalesmanIcon from '@material-ui/icons/PersonPinRounded';
import UniverseIcon from '@material-ui/icons/ScatterPlotRounded';
import ShoppingCartRounded from '@material-ui/icons/ShoppingCartRounded';
import ShopTwoRounded from '@material-ui/icons/ShopTwoRounded';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import TransferWithinAStationRoundedIcon from '@material-ui/icons/TransferWithinAStationRounded';
import ControlTowerIcon from '@material-ui/icons/WidgetsRounded';
import React, { useMemo, useState } from 'react';
import { getNodesSelectOptions } from 'utils/node';
import { byId, isInIframe } from 'utils/utils';
import Select from '../controlTower/components/Select';
import { ga, useGDispatch, useGState } from '../state/store';
import DatePicker from './inputs/DatePicker';
import Link from './Link';
import UserProfile from './UserProfile';

// import('./../../static/hamburgers/hamburgers.scss');

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  menuButton: {
    marginLeft: -theme.spacing(2),
    borderRadius: 0,
    backgroundColor: (props) =>
      props.IS_STAGING === 'true' ? '#ff5722' : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: (props) =>
        props.IS_STAGING === 'true' ? '#ff5722' : theme.palette.primary.main,
    },
  },
  menuButtonClose: {
    marginRight: theme.spacing(1),
    color: 'white',
  },
  ctLabel: {
    color: 'white',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
  homeLink: {
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  branchSelect: {
    width: 160,
    paddingLeft: theme.spacing(1),
    color: 'black',
  },
  children: {
    height: '100vh',
  },
  widgetsArea: {
    position: 'relative',
    height: '100%',
  },
  mapView: {
    height: 32,
    marginRight: theme.spacing(1),
    color: 'white',
  },
  'mapView:Mui-selected': {
    color: 'green !important',
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  menuLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    height: 48,
    display: 'flex',
  },
  subMenu: {
    paddingLeft: theme.spacing(4),
  },
  subSubMenu: {
    paddingLeft: theme.spacing(8),
  },
}));

// const branchSelectStyles = {
//   control: (base, state) => ({
//     ...base,
//     height: '34px',
//     'min-height': '34px',
//   }),
// };

const selectorCustomStyles = {
  option: (provided, { data }) => ({
    ...provided,
    color: data.isLeaf ? 'black' : 'blue',
  }),
};

const navItems = [
  {
    Icon: DAIcon,
    name: 'Delivery',
    subMenu: [
      {
        Icon: DAIcon,
        name: 'Execution',
        permission: 'tms.execution',
        id: '/dl/execution',
      },
      {
        Icon: DAIcon,
        name: 'Hold & Retry',
        id: '/dl/holdRetry',
        permission: 'tms.holdRetry',
      },
      {
        Icon: DAIcon,
        name: 'Planning',
        id: '/dl/planning',
        permission: 'tms.planning',
      },
    ],
  },
  {
    Icon: DashboardIcon,
    name: 'Sales',
    subMenu: [
      {
        Icon: DashboardIcon,
        name: 'Dashboard',
        permission: 'gtm.salesTeamDashboard',
        id: '/sales/stlPerformance',
      },
      {
        Icon: DashboardIcon,
        name: 'SalesRep',
        permission: 'gtm.spvApp',
        id: '/sales/salesRepView',
      },
    ],
  },
  { Icon: CollectionIcon, name: 'Remote Collection', id: '/ca', permission: 'collection' },
  {
    Icon: CollectionIcon,
    name: 'Inventory Management',
    id: '/inventory',
    permission: 'inventory',
  },
  // { Icon: ReportsIcon, name: 'SBMart Dashboard', id: '/sbmn/dashboard' },
  {
    Icon: ShoppingCartRounded,
    name: 'SB MART',
    subMenu: [
      {
        Icon: AccountBalanceRounded,
        name: 'Governance',
        permission: 'sbmart.governance',
        subMenu: [
          { name: 'Pricing', id: '/sbmn/pricing' },
          { name: 'Enable Promotions', id: '/sbmn/promotion' },
          { name: 'Add Promotions (SKU)', id: '/sbmn/promotionSKU' },
          { name: 'Add Promotions (Invoice)', id: '/sbmn/promotionInvoice' },
          { name: 'Add Promotions (Loyalty)', id: '/sbmn/promotionLoyalty' },
          { name: 'Add Promotions (Customer)', id: '/sbmn/promotionOutlet' },
          { name: 'Add Recommended Products', id: '/sbmn/recommendedProducts' },
          { name: 'Order Value Criteria', id: '/sbmn/supplierSpec' },
          { name: 'App Notifications', id: '/sbmn/notif' },
        ],
      },
      {
        Icon: AssignmentTurnedInRounded,
        name: 'Inventory',
        permission: 'sbmart.inventory',
        subMenu: [
          { name: 'Refresh Inventory', id: '/sbmn/inventoryRefresh' },
          { name: 'Download Current Inventory', id: '/sbmn/inventory' },
          {
            name: 'Inventory Report (Transactions)',
            id: '/sbmn/inventorySnapshotTxn',
          },
          {
            name: 'Inventory Report (Daywise)',
            id: '/sbmn/inventorySnapshotDay',
          },
        ],
      },
      {
        Icon: ShopTwoRounded,
        name: 'Orders',
        permission: 'sbmart.orders',
        subMenu: [
          { name: 'Orders', id: '/sbm/order' },
          { name: 'Download Invoices', id: '/sbmn/invoice' },
        ],
      },
      {
        Icon: DashboardIcon,
        name: 'Customers',
        permission: 'sbmart.retailers',
        id: '/sbm/retailers',
      },
    ],
  },

  {
    Icon: ControlTowerIcon,
    name: 'Control Tower',
    subMenu: [
      // { Icon: HomeIcon, name: 'Home', id: '/ct' },
      {
        Icon: DashboardIcon,
        name: 'Sales Dashboard',
        id: '/ct/dashboard/sales',
        permission: 'controlTower.salesDashboard',
      },
      {
        Icon: DashboardIcon,
        name: 'Rural Sales Dashboard',
        id: '/ct/dashboard/rspsales',
        permission: 'controlTower.rspDashboard',
      },
      {
        Icon: DashboardIcon,
        name: 'Delivery Dashboard',
        id: '/ct/dashboard/delivery',
        permission: 'controlTower.deliveryDashboard',
      },
      // {
      //   Icon: DashboardIcon,
      //   name: 'Auditing Dashboard',
      //   id: '/ct/auditingDashboard',
      //   permission: 'ct.dashboard.audit',
      // },
      {
        Icon: SalesmanIcon,
        name: 'Live Salesman',
        id: '/ct/map/sales',
        permission: 'controlTower.liveSalesRep',
      },
      {
        Icon: DAIcon,
        name: 'Live Delivery Agent',
        id: '/ct/map/delivery',
        permission: 'controlTower.liveDeliveryAgent',
      },
      {
        Icon: SalesmanIcon,
        name: 'Help',
        id: '/ct/help',
        isFile: true,
        url:
          'https://docs.google.com/document/d/1o-E3ciyNoMQFPA9Icg5Clx-_ohI1N_eU1_32gykMnx0/edit?usp=sharing',
        permission: 'controlTower.help',
      },
    ],
  },
  {
    Icon: ControlTowerIcon,
    name: 'X Dock',
    subMenu: [
      // { Icon: HomeIcon, name: 'Home', id: '/ct' },
      {
        Icon: DashboardIcon,
        name: 'Asset Management',
        id: '/xd/assetManagement',
        permission: 'xdock.assetManagement',
      },
      {
        Icon: DashboardIcon,
        name: 'Task Management',
        id: '/xd/taskManagement',
        permission: 'xdock.taskManagement',
      },
    ],
  },
  {
    Icon: HomeWorkRoundedIcon,
    name: 'WMS',
    permission: 'wms',
    subMenu: [
      {
        Icon: ListAltRoundedIcon,
        name: 'Cockpit',
        id: '/wms/outbound/2/cockpit',
      },
      {
        Icon: TransferWithinAStationRoundedIcon,
        name: 'Inbound',
        subMenu: [
          {
            Icon: AccountTreeIcon,
            name: 'Receiving',
            id: '/wms/receiving',
          },
          {
            Icon: TransferWithinAStationRoundedIcon,
            name: 'Receiving (Serialized)',
            id: '/wms/handover',
          },
          {
            Icon: AccountTreeIcon,
            name: 'Putaway',
            id: '/wms/putaway',
          },
        ],
      },
      {
        Icon: ListAltRoundedIcon,
        name: 'Outbound',
        permission: 'wms.outbound1',
        subMenu: [
          {
            Icon: ListAltRoundedIcon,
            name: 'Sorting Session',
            id: '/wms/outbound/sorting',
          },
          {
            Icon: ListAltRoundedIcon,
            name: 'Picking',
            id: '/wms/outbound/picking',
          },
          {
            Icon: ListAltRoundedIcon,
            name: 'Loading',
            id: '/wms/outbound/loading',
          },
        ],
      },
      {
        Icon: ListAltRoundedIcon,
        name: 'Outbound',
        permission: 'wms.outbound2',
        subMenu: [
          {
            Icon: ListAltRoundedIcon,
            name: 'Sorting',
            id: '/wms/outbound/2/sorting',
          },
          {
            Icon: ListAltRoundedIcon,
            name: 'Picking',
            id: '/wms/outbound/2/picking',
          },
          {
            Icon: ListAltRoundedIcon,
            name: 'Loading',
            id: '/wms/outbound/2/loading',
          },
        ],
      },
      {
        Icon: AccountTreeIcon,
        name: 'Returns',
        subMenu: [
          {
            Icon: AccountTreeIcon,
            name: 'Returns Receiving',
            id: '/wms/returns',
          },
          {
            Icon: AccountTreeIcon,
            name: 'Returns Putaway',
            id: '/wip/Returns Putaway',
          },
        ],
      },
      {
        Icon: AccountTreeIcon,
        name: 'Inventory',
        subMenu: [
          {
            Icon: AccountTreeIcon,
            name: 'Inventory Count',
            id: '/wms/ira',
          },
          {
            Icon: AccountTreeIcon,
            name: 'HU Info',
            id: '/wip/HU Info',
          },
          {
            Icon: AccountTreeIcon,
            name: 'Bin Consolidation',
            id: '/wms/inventory/bincon',
          },
        ],
      },
      {
        Icon: AccountTreeIcon,
        name: 'Replenishment',
        subMenu: [
          {
            Icon: AccountTreeIcon,
            name: 'Demand Based Replenishment',
            id: '/wip/Demand Based Replenishment',
          },
          {
            Icon: AccountTreeIcon,
            name: 'Automated Replenishment',
            id: '/wip/Automated Replenishment',
          },
        ],
      },
      {
        Icon: SupervisedUserCircleIcon,
        name: 'Masters',
        subMenu: [
          {
            Icon: SupervisedUserCircleIcon,
            name: 'Bins',
            id: '/wms/bins',
          },
          {
            Icon: SupervisedUserCircleIcon,
            name: 'Manpower',
            id: '/wms/employeeManagement',
          },
          {
            Icon: SupervisedUserCircleIcon,
            name: 'MHEs',
            id: '/wms/MHEs',
          },
          {
            Icon: SupervisedUserCircleIcon,
            name: 'HUs',
            id: '/wms/MHUs',
          },
        ],
      },
      {
        Icon: AccountTreeIcon,
        name: 'Exports',
        subMenu: [
          {
            Icon: AccountTreeIcon,
            name: 'Inventory Status',
            id: '/wms/inventoryStatus',
          },
        ],
      },
      {
        Icon: AssignmentIcon,
        name: 'Sorting Dashboard',
        id: '/wms/outbound/sorting/dashboard',
      },
      {
        Icon: AssignmentIcon,
        name: 'Reports',
        id: '/wms/fcReports',
      },
    ],
  },
  {
    Icon: DeveloperBoardRoundedIcon,
    name: 'Operations',
    id: '/ops/operations',
    permission: 'admin.operationsNew',
  },
  { Icon: LocalAtmIcon, name: 'Cashier', id: '/cashier/trip', permission: 'tms.externalCashier' },
  {
    Icon: UniverseIcon,
    name: 'Universe',
    subMenu: [
      {
        Icon: UniverseIcon,
        name: 'Customer Management',
        permission: 'universe.outletsManagement',
        id: '/ru',
      },
      {
        Icon: UniverseIcon,
        isPanelUrl: true,
        name: 'Distribution Coverage',
        permission: 'universe.distributionCoverage',
        id: '/universe',
      },
      {
        Icon: UniverseIcon,
        name: 'Geo Tagging',
        permission: 'universe.geotagging',
        subMenu: [
          {
            Icon: UniverseIcon,
            name: 'GT Dashboard',
            id: '/stl',
            isAppsUrl: true,
          },
          {
            Icon: UniverseIcon,
            name: 'Duplicates',
            id: '/duplicates',
            isAppsUrl: true,
          },
        ],
      },
      {
        Icon: UniverseIcon,
        name: 'Utilities',
        subMenu: [
          {
            Icon: UniverseIcon,
            name: 'Notifications & Broadcast',
            id: '/crm',
            permission: 'universe.crm',
            isAppsUrl: true,
          },
          {
            Icon: UniverseIcon,
            name: 'Auditing',
            permission: 'universe.auditingDashboard',
            id: '/ct/auditingDashboard',
          },
        ],
      },
    ],
  },
  { Icon: ReportsIcon, name: 'Reports', id: '/reports', permission: 'admin.reports' },
];

const permissionFilter = (permission) => (item) => {
  if (item.permission) return filterJustMenu(permission)(item);

  if (item.subMenu && item.subMenu.every((subM) => subM.permission)) {
    let filtered = permission.filter((o1) =>
      item.subMenu.some((o2) => o1.code.startsWith(o2.permission))
    );
    return Boolean(filtered.length);
  }

  return true;
};

const filterJustMenu = (permission) => (item) =>
  item.permission
    ? permission.map((item) => item.code).some((p) => p.startsWith(item.permission))
    : true;

export default function Layout({
  children,
  hideFilters,
  hideDateFilter,
  hideNodeSelector = false,
  location: { pathname },
  useNodeSelector,
  component,
  title,
}) {
  const inIframe = isInIframe();
  const IS_STAGING = useGState((s) => s[ga.PANEL_CONFIG].STAGING);
  const classes = useStyles({ IS_STAGING });
  const { date, branches, branch, lastUpdated, nodes, node, permission } = useGState((s) => ({
    date: s.date,
    branches: s.branches,
    branch: s.branch,
    lastUpdated: s.lastUpdated,
    nodes: s[ga.NODES],
    node: s[ga.NODE],
    permission: s.permission,
  }));
  const gDispatch = useGDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(
    navItems
      .filter((item) => item.subMenu)
      .reduce((acc, item) => ({ ...acc, [item.name]: true }), {})
  );

  const branchesOptions = useMemo(
    () =>
      branches.map((branch) => ({ value: branch.id, label: branch.name, isLeaf: branch.isLeaf })),
    [branches]
  );

  const nodeOptions = useMemo(() => (nodes.length ? getNodesSelectOptions(nodes) : []), [nodes]);

  const memoizedChildren = useMemo(() => children, [children]);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      {!inIframe ? (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(true)}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Box
              className={classes.title}
              display={isMobileView ? 'block' : 'flex'}
              alignItems="center"
              mr={2}
            >
              <Typography
                color="inherit"
                class={`whitespace-nowrap ${isMobileView ? 'text-base' : 'text-xl'}`}
              >
                {title ||
                  byId(
                    navItems.flatMap((item) =>
                      item.subMenu
                        ? item.subMenu.flatMap((item) => (item.subMenu ? item.subMenu : item))
                        : item
                    ),
                    pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
                  )?.name ||
                  decodeURI(pathname.split('/')[2]).replace('_', ' ')}
              </Typography>
              {lastUpdated && (
                <Box pl={isMobileView ? 0 : 2}>
                  <Typography variant="caption">{lastUpdated}</Typography>
                </Box>
              )}
            </Box>
            {component}
            {!hideFilters && (
              <>
                {!hideDateFilter && (
                  <Box class={`${isMobileView ? 'w-20' : ''}`}>
                    <DatePicker
                      value={date}
                      color="white"
                      width={isMobileView ? 80 : undefined}
                      onChange={(selectedDate) => {
                        if (selectedDate && !isNaN(selectedDate))
                          gDispatch([ga.DATE, selectedDate]);
                      }}
                    />
                  </Box>
                )}
                {!hideNodeSelector &&
                  (useNodeSelector ? (
                    <Select
                      className={classes.branchSelect}
                      options={nodeOptions}
                      styles={selectorCustomStyles}
                      value={nodeOptions?.find((el) => el.value === node?.id)}
                      onChange={(selectedNode) => {
                        gDispatch([ga.NODE, nodes?.find((el) => el.id === selectedNode.value)]);
                      }}
                    />
                  ) : (
                    <Select
                      className={classes.branchSelect}
                      options={branchesOptions}
                      value={branchesOptions.find((el) => el.value === branch.id)}
                      onChange={(selectedBranch) => {
                        gDispatch([
                          ga.BRANCH,
                          branches.find((el) => el.id === selectedBranch.value),
                        ]);
                      }}
                    />
                  ))}
              </>
            )}
          </Toolbar>
        </AppBar>
      ) : null}
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onClick={() => setDrawerOpen(false)}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar}>
          <Box display="flex" alignItems="center" width={1}>
            <Box flexGrow={1} pl={1}>
              <Typography variant="h6" className={classes.ctLabel} align="center">
                Panel
              </Typography>
            </Box>

            <IconButton
              onClick={() => setDrawerOpen(false)}
              edge="start"
              className={classes.menuButtonClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </div>

        <Divider />

        <List>
          {navItems.filter(permissionFilter(permission)).map(({ Icon, name, id, subMenu }, index) =>
            subMenu ? (
              <div key={index}>
                <ListItem
                  button
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuOpen((smo) => ({ ...smo, [name]: !smo[name] }));
                  }}
                >
                  <ListItemIcon>
                    <Icon.type />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                  {menuOpen[name] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={menuOpen[name]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {subMenu
                      .filter(permissionFilter(permission))
                      .map(
                        (
                          {
                            Icon,
                            name,
                            id,
                            isAppsUrl,
                            isPanelUrl,
                            subMenu: subSubMenu,
                            isFile,
                            url,
                          },
                          index
                        ) =>
                          subSubMenu ? (
                            <div key={index}>
                              <ListItem
                                button
                                className={classes.subMenu}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setMenuOpen((smo) => ({ ...smo, [name]: !smo[name] }));
                                }}
                              >
                                <ListItemIcon>
                                  <Icon.type />
                                </ListItemIcon>
                                <ListItemText primary={name} />
                                {menuOpen[name] ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={menuOpen[name]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {subSubMenu.map(({ name, id, isAppsUrl, isPanelUrl }, index) => (
                                    <Link
                                      to={id}
                                      key={index}
                                      className={classes.menuLink}
                                      isAppsUrl={isAppsUrl}
                                      isPanelUrl={isPanelUrl}
                                    >
                                      <ListItem
                                        button
                                        selected={pathname === id}
                                        className={classes.subSubMenu}
                                      >
                                        <ListItemText primary={name}></ListItemText>
                                      </ListItem>
                                    </Link>
                                  ))}
                                </List>
                              </Collapse>
                            </div>
                          ) : isFile ? (
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              key={index}
                              className={classes.menuLink}
                            >
                              <ListItem
                                button
                                selected={pathname === id}
                                className={classes.subMenu}
                              >
                                <ListItemIcon>
                                  <Icon.type></Icon.type>
                                </ListItemIcon>
                                <ListItemText primary={name}></ListItemText>
                              </ListItem>
                            </a>
                          ) : (
                            <Link
                              to={id}
                              key={index}
                              className={classes.menuLink}
                              isAppsUrl={isAppsUrl}
                              isPanelUrl={isPanelUrl}
                            >
                              <ListItem
                                button
                                selected={pathname === id}
                                className={classes.subMenu}
                              >
                                <ListItemIcon>
                                  <Icon.type></Icon.type>
                                </ListItemIcon>
                                <ListItemText primary={name}></ListItemText>
                              </ListItem>
                            </Link>
                          )
                      )}
                  </List>
                </Collapse>
              </div>
            ) : (
              <Link to={id} key={index} className={classes.menuLink}>
                <ListItem button selected={pathname === id}>
                  <ListItemIcon>
                    <Icon.type></Icon.type>
                  </ListItemIcon>
                  <ListItemText primary={name}></ListItemText>
                </ListItem>
              </Link>
            )
          )}
        </List>
        <UserProfile />
      </Drawer>
      <div className={classes.children}>
        <Box flexDirection="column" height={1} display="flex">
          {/* Shim Toolbar */}
          {!inIframe ? (
            <>
              <AppBar position="fixed" />
              <Toolbar variant="dense" />
            </>
          ) : null}
          {pageAccessAllowed({ pathname, permission }) ? (
            <Box className={classes.widgetsArea}>{memoizedChildren}</Box>
          ) : (
            <Box m={3}>
              <Typography>No Access</Typography>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
}

function pageAccessAllowed({ pathname, permission }) {
  const path = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
  const flatNavItems = [];

  navItems.forEach((item) => {
    let perm = item.permission;
    if (item.id) {
      flatNavItems.push({ path: item.id, perm });
    } else if (item.subMenu) {
      item.subMenu.forEach((sItem) => {
        let sPerm = sItem.permission || perm;
        if (sItem.id) {
          flatNavItems.push({ path: sItem.id, perm: sPerm });
        } else if (sItem.subMenu) {
          sItem.subMenu.forEach((ssItem) => {
            flatNavItems.push({ path: ssItem.id, perm: ssItem.permission || sPerm });
          });
        }
      });
    }
  });

  const item = flatNavItems.find((item) => item.path === path);

  if (!item || !item.perm) return true;
  return permission.some((p) => p.code.startsWith(item.perm));
}
